import { Platform, SocialOrHospital } from './enums';
// 项目内业务相关的配置
localStorage.setItem('BASE_URL', process.env.BASE_URL || '');
localStorage.setItem('WEBSOCKET_URL', process.env.WEBSOCKET_URL || '');
localStorage.setItem('PLATFORM', Platform.HXORGANIZATION.toString());
localStorage.setItem('USE_MOCK', process.env.USE_MOCK || '');
localStorage.setItem('INNER_HOSPITAL', process.env.INNER_HOSPITAL || `${SocialOrHospital.SOCIAL}`);
localStorage.setItem('IS_LOCALIZED_DEPLOYMENT', process.env.IS_LOCALIZED_DEPLOYMENT || '');
localStorage.setItem('STATIC_RESOURCE_SERVER', process.env.STATIC_RESOURCE_SERVER || '');
localStorage.setItem('INTRANET', process.env.INTRANET || '');
localStorage.setItem('H5_DOMAIN', process.env.H5_DOMAIN || '');

const paramStr = window.location.hash.match(/\?(.+)/);
if (paramStr) {
  const params = paramStr[1].split('&');
  const paramsObj: { [key: string]: string } = params.reduce((memo, item) => {
    const [key, value] = item.split('=');
    return { ...memo, [key]: value };
  }, {});
  // 有token则写入token
  if (paramsObj.token) {
    localStorage.setItem('ADMIN_TOKEN', paramsObj.token);
  }

  // 有baseUrl则写入BASE_URL
  if (paramsObj.baseUrl) {
    // 用于服务器访问本机服务
    localStorage.setItem('BASE_URL', `http://${window.decodeURIComponent(paramsObj.baseUrl)}`);
  }
  // 有innerHospital则写入INNER_HOSPITAL（预留，暂时没需求需要用上）
  if (paramsObj.innerHospital) {
    // 通过 URL 决定进入的是院内版还是院外版
    localStorage.setItem('INNER_HOSPITAL', paramsObj.innerHospital);
  }
  // 有innerHospitalAccountType则写入INNER_HOSPITAL_ACCOUNT_TYPE（预留，暂时没需求需要用上）
  if (paramsObj.innerHospitalAccountType) {
    // 用于院内版 通过 URL 决定进入的是管理端还是受试者端
    localStorage.setItem('INNER_HOSPITAL_ACCOUNT_TYPE', paramsObj.innerHospitalAccountType);
  }
}

/**
 * for模块联邦
 * TODO: 后期需要兼容回qiankun
 */
import('./bootstrap');

export {};
